import React from "react"
import {Grid, makeStyles} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  topContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  innerGridItem: {
    padding: theme.spacing(2),
    border: `0.8px solid ${theme.palette.secondary[400]}`,
    borderRadius: "10px",

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  image: {
    [theme.breakpoints.down("sm")]: {
      height: "250px",
    },
    [theme.breakpoints.up("md")]: {
      height: "260px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "300px",
    },

  },
}))

const Gallery = ({ images }) => {
  const classes = useStyles()

  return <Grid container>
    {images.map(entry => {
      return <Grid item key={entry.alt}>
        <Grid container justify={"center"} className={classes.topContainer}>
          <Grid item className={classes.innerGridItem}>
            <img src={entry.img} alt={entry.alt} className={classes.image}/>
          </Grid>
        </Grid>
      </Grid>
    })}
  </Grid>
}

export default Gallery