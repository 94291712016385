import React from "react"
import { Grid, Typography, withStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import linkedIn from "../../../imgs/socials/linkedin.svg"
import github from "../../../imgs/socials/github.svg"
import linkedInWhite from "../../../imgs/socials/linkedin_white.svg"
import youtube from "../../../imgs/socials/youtube.svg"
import web from "../../../imgs/socials/web.svg"

export const Social = ({ items }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant={'subtitle2'} color={'textSecondary'}>
        Social
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <SocialHandles items={items} />
    </Grid>
  </Grid>
);

const SocialHandles = ({ classes, items, alignFlexEnd }) => (
  <Grid container justify={alignFlexEnd ? "flex-end" : "center"}>
    {items.map((item, i) => (
      <Grid item key={i}>
        <IconButton aria-label={item.name} onClick={() => window.open(item.url, "_blank")}>
          <img alt={item.name} src={getImageForSocial(item.name)} className={classes.icon} loading={"lazy"}/>
        </IconButton>
      </Grid>
    ))}
  </Grid>
);

export const getImageForSocial = socialName =>
  ({
    linkedIn: linkedIn,
    github: github,
    linkedInWhite: linkedInWhite,
    youtube: youtube,
    web: web
  }[socialName]);

const styles = theme => ({
  icon: {
    height: 30,
    width: 30
  }
});

export default withStyles(styles)(SocialHandles);