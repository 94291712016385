import Gallery from "../../../../src/components/Articles/Gallery";
import phone1a from "../../../../src/imgs/articles/graph-101/phone-1a.svg";
import social2a from "../../../../src/imgs/articles/graph-101/social-2a.svg";
import flight3a from "../../../../src/imgs/articles/graph-101/flight-3a.svg";
import flightDirected from "../../../../src/imgs/articles/graph-102/flight-directed.svg";
import socialDirected from "../../../../src/imgs/articles/graph-102/social-directed.svg";
import phoneDirected from "../../../../src/imgs/articles/graph-102/phone-directed.svg";
import AnchorLink from "../../../../src/components/Articles/AnchorLink";
import directedMultipleEdge from "../../../../src/imgs/articles/graph-102/directed-graph-multiple-edge.svg";
import undirectedMultipleEdge from "../../../../src/imgs/articles/graph-102/undirected-graph-multiple-edge.svg";
import directedGraphLoop from "../../../../src/imgs/articles/graph-102/direct-graph-loop.svg";
import undirectedGraphLoop from "../../../../src/imgs/articles/graph-102/undirected-graph-loop.svg";
import * as React from 'react';
export default {
  Gallery,
  phone1a,
  social2a,
  flight3a,
  flightDirected,
  socialDirected,
  phoneDirected,
  AnchorLink,
  directedMultipleEdge,
  undirectedMultipleEdge,
  directedGraphLoop,
  undirectedGraphLoop,
  React
};