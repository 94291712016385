// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-articles-article-layout-jsx": () => import("./../../../src/components/Articles/ArticleLayout.jsx" /* webpackChunkName: "component---src-components-articles-article-layout-jsx" */),
  "component---src-components-posts-post-layout-jsx": () => import("./../../../src/components/Posts/PostLayout.jsx" /* webpackChunkName: "component---src-components-posts-post-layout-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-course-detail-first-steps-with-scala-js": () => import("./../../../src/pages/courseDetail/first-steps-with-scala.js" /* webpackChunkName: "component---src-pages-course-detail-first-steps-with-scala-js" */),
  "component---src-pages-course-detail-material-ui-with-react-js": () => import("./../../../src/pages/courseDetail/material-ui-with-react.js" /* webpackChunkName: "component---src-pages-course-detail-material-ui-with-react-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-event-loop-javascript-js": () => import("./../../../src/pages/event-loop-javascript.js" /* webpackChunkName: "component---src-pages-event-loop-javascript-js" */),
  "component---src-pages-fullstack-javascript-tsx": () => import("./../../../src/pages/fullstack-javascript.tsx" /* webpackChunkName: "component---src-pages-fullstack-javascript-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-mastering-javascript-tsx": () => import("./../../../src/pages/mastering-javascript.tsx" /* webpackChunkName: "component---src-pages-mastering-javascript-tsx" */),
  "component---src-pages-material-ui-tsx": () => import("./../../../src/pages/material-ui.tsx" /* webpackChunkName: "component---src-pages-material-ui-tsx" */),
  "component---src-pages-mentoring-tsx": () => import("./../../../src/pages/mentoring.tsx" /* webpackChunkName: "component---src-pages-mentoring-tsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-react-fundamentals-tsx": () => import("./../../../src/pages/react-fundamentals.tsx" /* webpackChunkName: "component---src-pages-react-fundamentals-tsx" */),
  "component---src-pages-react-redux-tsx": () => import("./../../../src/pages/react-redux.tsx" /* webpackChunkName: "component---src-pages-react-redux-tsx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

