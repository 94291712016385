import VideoPlayer from "../../../../src/components/Video/VideoPlayer";
import CodeSandbox from "../../../../src/components/Posts/CodeSandbox";
import Transcript from "../../../../src/components/Video/Transcript";
import Share from "../../../../src/components/Video/Share";
import * as React from 'react';
export default {
  VideoPlayer,
  CodeSandbox,
  Transcript,
  Share,
  React
};