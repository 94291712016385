import { Tab, Tabs, withStyles } from '@material-ui/core';
import React from 'react';

const AppMenu = ({ value, items, onChange, classes, props }) => (
  <Tabs
    value={value}
    onChange={onChange}
    classes={{
      indicator: classes.indicator
    }}
  >
    {items.map((item, i) => (
      <Tab aria-label={item.name} label={item.name} key={i} />
    ))}
  </Tabs>
);

const styles = theme => ({
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
});

const StyledMenu = withStyles(styles)(AppMenu);
export default StyledMenu;
