import React from "react"
import VimeoPlayer from "./VimeoPlayer"
import YouTubePlayer from "./YouTubePlayer"

export default function VideoPlayer({ title, vimeoVideoId, youtubeId }) {
  return (
    <div id="bl_video_player" data-bl-video-title={title} data-bl-youtube-Id={youtubeId}>
      {!youtubeId && <VimeoPlayer title={title} vimeoVideoId={vimeoVideoId}/>}
      {youtubeId && <YouTubePlayer videoId={youtubeId}/>}
    </div>
  )
}
