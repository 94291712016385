import React from "react"
import Highlight, { defaultProps } from "prism-react-renderer"
import theme from "prism-react-renderer/themes/oceanicNext"
import { makeStyles, Grid } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  codeContainer: {
    height: "50vh",
    overflowY: "scroll",
    borderRadius: "6px",
    boxShadow: theme.shadows[10]
  },
  codeBlock: {
    paddingLeft: theme.spacing(3),
    fontFamily: '"Roboto Mono", "Fira Code", monospace',
    fontSize: "15px",
    overflowX: "scroll",
    [theme.breakpoints.only('xs')]: {
      fontSize: "10px",
    }
  },
}))

export default ({ children, className, style }) => {
  const classes = useStyles()
  const language = className.replace(/language-/, "")
  return (
    <Grid container className={classes.codeContainer} style={{backgroundColor: theme.plain.backgroundColor}}>
      <Highlight
        {...defaultProps}
        theme={theme}
        code={children}
        language={language}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre className={classes.codeBlock} style={{ ...style }}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </Grid>
  )
}
