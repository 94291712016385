import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import * as React from "react"

interface SEOProps {
  title: string;
  description: string;
  imagePath?: string;
  pathname?: string;
  lang?: string
}
const SEO = ({ title, description, imagePath, pathname, lang = 'en' }: SEOProps) => (
  <StaticQuery
    query={query}
    render={({
               site: {
                 siteMetadata: {
                   defaultTitle,
                   titleTemplate,
                   defaultDescription,
                   siteUrl,
                 },
               },
             }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || "/"}`,
      }

      return (
        <>
          <Helmet
            title={seo.title}
            titleTemplate={titleTemplate}
            htmlAttributes={{ lang }}
          >
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta name="description" content={seo.description}/>
            <meta name="robots" content="all"/>
            <link rel="canonical" href={seo.url}/>

            {/* For Twitter */}
            <meta name="twitter:card" content="summary_large_image"/>
            {imagePath && (
              <meta name="twitter:image" content={`${siteUrl}${imagePath}`}/>
            )}

            {/* For Open Graph */}
            {seo.url && <meta property="og:url" content={seo.url}/>}
            {seo.title && <meta property="og:title" content={seo.title}/>}
            {seo.description && (
              <meta property="og:description" content={seo.description}/>
            )}
            {imagePath && (
              <meta property="og:image" content={`${siteUrl}${imagePath}`}/>
            )}
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
      }
    }
  }
`
