import courseFirstStepsInScala from "../imgs/courses/course_first_steps_in_scala.svg"
import courseMaterialBasic from "../imgs/courses/course_material_ui_basic.svg"
import courseEventLoopJs from "../imgs/courses/course_event-loop-js.svg"
import js from "../imgs/technologies/js.svg"
import nodejs from "../imgs/technologies/nodejs.svg"
import react from "../imgs/technologies/react.svg"
import scala from "../imgs/technologies/scala.svg"
import gatsby from "../imgs/technologies/gatsby.svg"
import typescript from "../imgs/technologies/typescript.svg"
import graphql from "../imgs/technologies/graphql.svg"
import { FirstStepsInScala } from "./courseDetailFiles/firstStepsInScala"
import { MaterialUiBasic } from "./courseDetailFiles/materialUiBasic"
import { EventLoopJavaScript } from "./courseDetailFiles/eventLoopJavascript"
import deeksha from "../imgs/members/deeksha.jpeg"
import harit from "../imgs/members/harit.jpeg"

const FunctionsUrl = `https://us-central1-${process.env.REACT_APP_FB_PROJECT_ID}.cloudfunctions.net`

export const FunctionsEndpoint = {
  contact: FunctionsUrl + "/sendEmailToTeam",
}

export const Routes = {
  appRoot: "/",
  courses: "/courses",
  team: "/team",
  about: "/about",
  privacy: "/privacy",
  terms: "/terms",
  blog: "/blog",
  learn: "/learn",
  mentoring: "/mentoring",
}

export const AppMenus = [
  { name: "Learn", route: Routes.learn },
  { name: "Mentoring", route: Routes.mentoring },
  { name: "Courses", route: Routes.courses },
  { name: "Team", route: Routes.team },
  { name: "About", route: Routes.about },
  { name: "Blog", route: Routes.blog },
]

const getAuthorImage = (author) => {
  return (author === "Deeksha Sharma") ? deeksha : harit
}

export const getAuthorsWithImage = authors => authors.map(author => Object.assign({}, {
  name: author,
  image: getAuthorImage(author),
}))

export const ExternalRoutes = {
  myCourses: "https://bonsaiilabs.thinkific.com/users/sign_in",
}

export const BannerData = {
  main: "Learn to build software for the modern web",
  secondary:
    "Our goal is to simplify the acquisition of software development skills through mentoring, videos, courses, articles, and tech zines.",
  callToAction: {
    buttonLabel: "VIEW COURSES",
    chat: {
      headline: "Need help in learning?",
      buttonLabel: "CONTACT US",
    },
  },
}

// Id is First part
export const getCourseDetailFor = id =>
  ({
    "material-ui-with-react": MaterialUiBasic,
    "first-steps-with-scala": FirstStepsInScala,
    "event-loop-javascript": EventLoopJavaScript,
  }[id])

// todo: should come from courseDetailFiles.js
export const CoursesData = [
  {
    id: "material-ui-with-react",
    name: "Material-UI with React",
    level: "Intermediate",
    duration: "2h 10m",
    img: courseMaterialBasic,
    urlPrefix: "courseDetail",
  },
  {
    id: "first-steps-with-scala",
    name: "First Steps with Scala",
    level: "Beginner",
    duration: "57m",
    img: courseFirstStepsInScala,
    urlPrefix: "courseDetail",
  },
  {
    id: "event-loop-javascript",
    name: "Asynchronous JavaScript and Event Loop",
    level: "Beginner",
    duration: "1h 10m",
    img: courseEventLoopJs,
  },
]

export const faqs = [
  {
    question: "Is my payment secure?",
    answer:
      "Yes, the payments are 100% secured. They are processed by Stripe, which is also used by companies such as Lyft, Instacart, Salesforce, Shopify and many others.",
  },
  {
    question: "Do you offer any discounts?",
    answer:
      "Yes, if you are a group of 10 or more, please contact us at team@bonsaiilabs.com",
  },
  {
    question: "Are there any setup/technical requirements?",
    answer:
      "Yes, and there is complete documentation guide to help you make progress.",
  },
  {
    question: "Do you provide 1:1 mentoring?",
    answer:
      "Yes, we do provide 1:1 paid support. Please reach us at team@bonsaiilabs.com to talk about your requirements.",
  },
]

export const TeamMembersData = [
  {
    name: "Deeksha Sharma",
    photoName: "deeksha",
    about:
      "Deeksha is an experienced software professional with extensive experience in product & engineering roles. Since 2007, she added value to domains such as banking, finance, open-source licensing, eCommerce, and online education. She is also the core contributor and mentor here at Bonsaiilabs. A small list of organisations she worked with include Spark New Zealand, Revenera (Software Vulnerability Team), BonsaiiLabs, Westpac New Zealand, Bank of Montreal, Crystallize, Moneris, Nomis Solutions, Pluralsight Inc, Forkfacts (Stealth mode startup).",
    social: [
      { name: "web", url: "https://www.deeksha.dev/" },
      { name: "github", url: "https://github.com/deekshasharma" },
      { name: "linkedIn", url: "https://www.linkedin.com/in/deekshasharma/" },
    ],
  },
  {
    name: "Harit Himanshu",
    photoName: "harit",
    about:
      "Harit Himanshu is a hands-on software professional with more than a decade of experience in building and shipping products at companies of small to large sizes. He contributed to various domains such as Advertising, CleanTech, Security, and FinTech. He is passionate about writing clean testable code and prefers being lazy when coding. In his free time, he is found tinkering new technologies, learning new business domains, reading books, cooking, singing and investing his time with his wife on long walks.",
    social: [
      { name: "web", url: "https://www.harit.dev/" },
      { name: "github", url: "https://github.com/hhimanshu" },
      { name: "linkedIn", url: "https://www.linkedin.com/in/hhimanshu/" },
    ],
  },
]

export const bonsaiiSocialChannels = [
  { name: "youtube", url: "https://www.youtube.com/channel/UC0yZBnRsD9JRqLXBkfGym0Q/videos" },
  { name: "github", url: "https://github.com/bonsaiilabs" },
  { name: "linkedIn", url: "https://www.linkedin.com/company/bonsaiilabs/" },
]

export const PageTitles = {
  courses: {
    title: "Courses",
    description:
      "Explore our well structured courses with everything you need to know about solving problems using modern technologies.",
  },
  work: {
    title: "Work",
    description: "Learn more about our past and current work",
  },
  team: {
    title: "Team",
    description: "Meet the awesome team behind bonsaiilabs",
  },
  about: {
    title: "About",
    description: "Learn about Deeksha and Harit's journey behind bonsaiilabs",
  },
  contactUs: {
    title: "Contact Us",
    description: "Let us know how can we help you!",
  },
  blog: {
    title: "Blog",
    description: "Our latest content and updates for developers and companies",
  },
  learn: {
    title: "Learn",
    description: "Explore our collection of videos and articles on modern web development and computer science",
  },
  mentoring: {
    title: "Mentoring",
    description: "Our services to individuals and teams for better learning outcomes",
  },
  masteringJavaScript: {
    title: "Mastering JavaScript",
  },
  reactFundamentals: {
    title: "React Fundamentals",
  },
  reactRedux: {
    title: "Developing Apps using React & Redux",
  },
  materialUi: {
    title: "Developing Beautiful Apps with Material-UI",
  },
  fullStackJS: {
    title: "Developing Fullstack apps with JavaScript & NodeJS",
  },
}

export const CourseDetails = {
  defaultShowModules: 2,
  defaultShowClips: 3,
}

export const TechnologiesData = [
  { name: "Javascript", image: js },
  { name: "NodeJS", image: nodejs },
  { name: "ReactJS", image: react },
  { name: "Scala", image: scala },
  { name: "TypeScript", image: typescript },
  { name: "Gatsby", image: gatsby },
  { name: "GraphQL", image: graphql },
]
export const ContactUsData = {
  autoHideDuration: 3000,
  messages: {
    empty: "Please fill the complete form",
    invalidEmail: "Please provide a valid email",
    thankYou: "Your message has been sent. Thank you!",
  },
}
/**
 * Only 1 news will be shown across the app at any time.
 * The pages who do not wish to show the news will pass 'hideNews'
 * to the Layout Component
 * @type {{destinationUrl: string, message: string}}
 */
export const NewsData = {
  message: "Read new article on Graph Data Structure ",
  destinationUrl: "/what-is-graph-data-structure",
}
