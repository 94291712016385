import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  transcript: {
    border: `2px solid ${theme.palette.secondary[800]}`,
    height: "50vh",
    padding: theme.spacing(2),
    overflowY: "scroll",
  },
}))

export default function Transcript({ data }) {
  const classes = useStyles()
  return (
      <Grid container className={classes.transcript}>
        {data.map((item, k) => (
          <Typography key={k} color={"textPrimary"} paragraph variant={"body1"}>
            {item}
          </Typography>
        ))}
      </Grid>
  )
}
