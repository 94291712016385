import React from "react"
import { withTheme } from "@material-ui/core"

export const Margin = ({ theme, top = 3, bottom = 3}) => {
  return <div
    style={{
      marginTop: theme.spacing(top),
      marginBottom: theme.spacing(bottom),
    }}
  />
}

export default withTheme(Margin)