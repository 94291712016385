import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#3640FF',
      400: '#5962FF',
      200: '#A4A5FF',
      50: "#EAEAFF"
    },
    secondary: {
      main: '#FFFFFF',
      light: 'rgba(255, 255, 255, 0.6)',
      surface: '#F8F9FA',
      800: '#606060',
      500: '#c2c2c2',
      400: '#DEDEDE',
      200: '#f5f5f5',
    },
    text: {
      primary: 'rgba(0,0,0,1)',
      secondary: 'rgba(0,0,0,0.6)',
    }
  },
  typography: {
    fontFamily: ['rubik'],
  }
}));
