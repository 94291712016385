import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Routes } from "../../shared/constants"
import { Link } from "gatsby"

export const PolicyLinks = ({ classes }) => (
  <nav>
    <Grid container spacing={2} justify={"flex-end"}>
      <Grid item>
        <ExternalLink destinationUrl={Routes.privacy} title="Privacy" />
      </Grid>
      <Grid item>
        <ExternalLink destinationUrl={Routes.terms} title="Terms" />
      </Grid>
    </Grid>
  </nav>
)

export const ExternalLink = ({ destinationUrl, title }) => (
  <Link to={destinationUrl} target="_blank" rel="noopener noreferrer">
    <Typography variant="body2">{title}</Typography>
  </Link>
)
