export const FirstStepsInScala = {
  id: "17f25caf",
  name: "First Steps with Scala",
  level: "Beginner",
  duration: "57m",
  buyLabel: "Enroll now",
  buyUrl: "https://bonsaiilabs.thinkific.com/enroll/438846?price_id=459428",
  reviews: [
    {
      name: "Sourav Mehta",
      review: "Excellent teaching pattern.",
    },
    {
      name: "Satish Kumar",
      review: "Must watch",
    },
  ],
  details: {
    price: "FREE",
    smallDesc: "Learn the basics of Functional Programming concepts in Scala",
    about: {
      descriptions: [
        "Scala is a blend of Object-Oriented and Functional Programming Paradigms. It has a big impact in every aspect of application development including but not limited to Servers, APIs, Distributed Systems, Front-End, Big Data Processing, and Machine Learning.",
        "This course teaches how to get started with first few basic concepts in Scala with hands-on examples.",
      ],
      learn: [
        "Understanding Immutability, Referential Transparency",
        "Understanding Scala Type Hierarchy",
        "Understanding of Fundamental Types in Scala",
        "Understanding of Purity and Side-Effects",
        "Working with Scala REPL",
        "Working with Functions in Scala",
        "Working with Control Structures",
        "Overview of Scala Ecosystem",
      ],
      preReq: [
        "You must have working knowledge of at least one programming language",
      ],
      sideBar: {
        includes: ["Lifetime Access", "Free Future Course Updates"],
      },
    },
    instructors: ["Harit Himanshu"],
    syllabus: [
      {
        moduleName: "Module 01: Why Scala?",
        moduleDuration: "19m",
        numVideos: 7,
        clips: [
          { id: 1, duration:"00:56", name: "01. Agenda", videoId: "391627861" },
          { id: 2, duration:"00:58", name: "02. Why Should I Learn?" },
          { id: 3, duration:"02:22", name: "03. But Why Scala?" },
          { id: 4, duration:"05:41", name: "04. What Are Some Of Scala Features?" },
          { id: 5, duration:"03:03", name: "05. About The Author" },
          { id: 6, duration:"05:25", name: "06. Is Scala Relevant For Me?", videoId: "391627035" },
          { id: 7, duration:"00:38", name: "07. Summary" },
        ],
      },
      {
        moduleName: "Module 02: Introduction",
        moduleDuration: "9m",
        numVideos: 6,
        clips: [
          { id: 8, duration: "00:26", name: "01. Agenda" },
          { id: 9, duration: "01:00", name: "02. What will you learn in this course" },
          { id: 10, duration: "00:39", name: "03. What are prerequisites for this course?" },
          { id: 11, duration: "04:02", name: "04. Installing Scala On Your Machine" },
          { id: 12, duration: "03:19", name: "05. Working with Scala REPL mode", videoId: "391627193" },
          { id: 13, duration: "00:24", name: "06. Summary" },
        ],
      },
      {
        moduleName: "Module 03: First Steps with Scala",
        moduleDuration: "15m",
        numVideos: 5,
        clips: [
          { id: 14, duration: "00:26", name: "01. Agenda" },
          { id: 15, duration: "06:47", name: "02. Working with Fundamental Scala Types" },
          { id: 16, duration: "03:39", name: "03. Understanding Immutability in Scala", videoId: "391627078" },
          { id: 17, duration: "03:39", name: "04. Working with Control Structures" },
          { id: 18, duration: "00:26", name: "05. Summary" },
        ],
      },
      {
        moduleName: "Module 04: The Functions of Functional Programming",
        moduleDuration: "15m",
        numVideos: 6,
        clips: [
          { id: 19, duration: "00:37", name: "01. Agenda" },
          { id: 20, duration: "03:48", name: "02. What is a Function?" },
          { id: 21, duration: "03:53", name: "03. The Purity and Side-effects of a Function" },
          { id: 22, duration: "03:10", name: "04. What is Referential Transparency?", videoId: "391627135" },
          { id: 23, duration: "03:43", name: "05. What are other types of Functions?" },
          { id: 24, duration: "00:26", name: "06. Summary" },
        ],
      },
      {
        moduleName: "Module 05:  What's Next?",
        moduleDuration: "4m",
        numVideos: 5,
        clips: [
          { id: 25, duration: "00:25", name: "01. Agenda" },
          { id: 26, duration: "00:52", name: "02. Practice the basics of the language" },
          { id: 27, duration: "01:01", name: "03. Where Do I Get Help?", videoId: "391627331" },
          { id: 28, duration: "01:02", name: "04. More Scala Learning" },
          { id: 29, duration: "01:02", name: "05. Thank You" },
        ],
      },
    ],
    faqs: [
      {
        question: "When will I have access to course videos?",
        answer: "Once you enroll for the course, you will get the lifetime access to the course videos.",
      },
    ],
  },
}
