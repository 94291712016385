import { makeStyles, Grid } from "@material-ui/core"
import * as React from "react"

const DEFAULT_PLAYER_COLUMN_WIDTH = 10
const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    paddingTop: "56.25%",
    marginBottom: theme.spacing(4),
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
}))

export default function VimeoPlayer({ title, vimeoVideoId, videoPlayerClass, videoPlayerColumnWidth }) {
  const classes = useStyles()
  return (
    <Grid container className={videoPlayerClass || classes.root} item
          md={videoPlayerColumnWidth || DEFAULT_PLAYER_COLUMN_WIDTH}>
      <iframe title={title}
              src={`https://player.vimeo.com/video/${vimeoVideoId}`}
              frameBorder="0"
              allow="autoplay; fullscreen"
              loading="lazy"
              className={classes.iframe}/>
    </Grid>
  )
}
