import { useEffect } from "react"
import * as React from "react"

// todo (h2): Player color customization
export const VideoPlyr = ({ youtubeVideoId }) => {
  useEffect(() => {
    const options = {
      noCookie: false,
      rel: 0,
      showinfo: 1,
      iv_load_policy: 3,
      modestbranding: 1,
    }
    if (typeof document !== `undefined`) {
      const Plyr = require("plyr")
      require("plyr/dist/plyr.css")
      const player = new Plyr(".js-plyr", options)
      player.source = {
        type: "video",
        sources: [
          {
            src: youtubeVideoId,
            provider: "youtube",
          },
        ],
      }
    }
  })

  return <div style={{ width: "100%" }}>
    <video className="js-plyr plyr"/>
  </div>
}