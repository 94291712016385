import { makeStyles, Grid } from "@material-ui/core"
import { VideoPlyr } from "./VideoPlyr"
import * as React from "react"

const DEFAULT_PLAYER_COLUMN_WIDTH = 10
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}))

export default function YouTubePlayer({ videoId }) {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} item
          md={DEFAULT_PLAYER_COLUMN_WIDTH}>
      <VideoPlyr youtubeVideoId={videoId}/>
    </Grid>
  )
}
