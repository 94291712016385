import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

export const MobileMenu = ({ items, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(value) {
    setAnchorEl(null);

    /**
     * Send event as null, because onChange in AppMenu uses Tab Component
     * that sends the event as first parameter when Tab is clicked. The second
     * item, value is important to us to load the route based on the click.
     */
    onChange(null, value);
  }

  return (
    <>
      <IconButton aria-label={"More"} aria-haspopup="true" onClick={handleClick} color="inherit">
        <MoreIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item, i) => (
          <MenuItem onClick={() => handleClose(i)} key={i}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
