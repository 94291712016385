import React from "react"
import { Button } from "@material-ui/core"

export default function CodeSandbox({sandboxUrl}) {

  return (
    <Button
      variant="contained"
      color="primary"
      size={"large"}
      onClick={() => window.open(sandboxUrl, "_blank")}
    >
      Open In CodeSandbox
    </Button>
  )
}