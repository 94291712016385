import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Typography } from "@material-ui/core"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import CodeBlock from "../components/Video/CodeBlock"

// todo: use theme
export const MyH1 = props => <Typography variant="h1" {...props} />
export const MyH2 = props => (
  <Typography
    variant="h2"
    style={{ marginTop: "4vh", marginBottom: "4vh" }}
    {...props}
  />
)
export const MyH4 = props => (
  <Typography
    variant="h4"
    style={{ marginTop: "4vh", marginBottom: "4vh" }}
    {...props}
  />
)
export const MyH5 = props => (
  <Typography
    variant="h5"
    style={{ marginTop: "4vh", marginBottom: "4vh" }}
    {...props}
  />
)
export const MyH6 = props => (
  <Typography
    gutterBottom
    variant="h6"
    style={{ marginTop: "4vh", marginBottom: "4vh" }}
    {...props}
  />
)
export const MyBody1 = props => (
  <Typography
    variant="body1"
    paragraph
    style={{fontSize: '16px'}}
    {...props}
  />
)

export const InlineCode = ({ children }) => (
  <code
    style={{
      padding: "0.5vh",
      backgroundColor: "#e2e1e1",
    }}
  >
    {children}
  </code>
)

export default function MdxLayout({
  children,
  title,
  excerpt,
  slug,
  imagePath,
}) {
  return (
    <>
      <SEO
        title={title}
        description={excerpt}
        pathname={slug}
        imagePath={imagePath}
      />
      <MDXProvider
        components={{
          h1: MyH1,
          h2: MyH2,
          h4: MyH4,
          h5: MyH5,
          h6: MyH6,
          p: MyBody1,
          code: props => <CodeBlock {...props} />,
          inlineCode: props => <InlineCode {...props} />,
        }}
      >
        <Layout selectedTabValue={false}>{children}</Layout>
      </MDXProvider>
    </>
  )
}
