import phone1a from "../../../../src/imgs/articles/graph-101/phone-1a.svg";
import phone1b from "../../../../src/imgs/articles/graph-101/phone-1b.svg";
import phone1c from "../../../../src/imgs/articles/graph-101/phone-1c.svg";
import social2a from "../../../../src/imgs/articles/graph-101/social-2a.svg";
import social2b from "../../../../src/imgs/articles/graph-101/social-2b.svg";
import social2c from "../../../../src/imgs/articles/graph-101/social-2c.svg";
import flight3a from "../../../../src/imgs/articles/graph-101/flight-3a.svg";
import flight3b from "../../../../src/imgs/articles/graph-101/flight-3b.svg";
import flight3c from "../../../../src/imgs/articles/graph-101/flight-3c.svg";
import Gallery from "../../../../src/components/Articles/Gallery";
import AnchorLink from "../../../../src/components/Articles/AnchorLink";
import Margin from "../../../../src/components/Articles/Margin";
import * as React from 'react';
export default {
  phone1a,
  phone1b,
  phone1c,
  social2a,
  social2b,
  social2c,
  flight3a,
  flight3b,
  flight3c,
  Gallery,
  AnchorLink,
  Margin,
  React
};