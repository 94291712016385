import React from "react"
import { Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import { Logo } from "../Logo"
import { PolicyLinks } from "./PolicyLinks"
import SectionP from "../Common/SectionP"
import FavoriteIcon from "@material-ui/icons/Favorite"
import PublicIcon from "@material-ui/icons/Public"
import { bonsaiiSocialChannels } from "../../shared/constants"
import SocialHandles from "../Team/MemberDetails/Social"

export default function AppFooter() {
  const classes = useStyles()

  return (
    <SectionP moreClasses={classes.section}>
      <footer>
        <Divider className={classes.divider}/>
        <Grid container style={{ border: "0px solid red" }}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="space-around">
              <Grid item xs={2} className={classes.logo}>
                <Logo/>
              </Grid>
              <Grid item xs={5} md={2} lg={1}>
                <PolicyLinks/>
              </Grid>
              <Grid item xs={5} md={2} style={{ border: "0px solid green" }}>
                <SocialHandles items={bonsaiiSocialChannels} alignFlexEnd={true}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MadeWithLove classes={classes}/>
          </Grid>
          <Grid item xs={12} className={classes.noTracking}>
            <Grid container justify="center">
              <Grid item>
                <Typography variant={"caption"} color={"textSecondary"}>
                  We neither track you nor identify you using tracker or cookies.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </SectionP>
  )
}

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(5),
  },
  logo: {
    paddingLeft: theme.spacing(2),
  },
  loveImage: {
    color: "red",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  madeWithLoveContainer: {
    paddingTop: theme.spacing(2),
  },
  noTracking: {
    paddingTop: theme.spacing(2),
  },
  earthImage: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.secondary[800],
  },
}))

const MadeWithLove = ({ classes }) => {
  return (
    <Grid container justify="center" className={classes.madeWithLoveContainer}>
      <Grid item>
        <Typography variant={"caption"} color="textSecondary">
          Handcrafted with
        </Typography>
      </Grid>
      <Grid item>
        <FavoriteIcon className={classes.loveImage} fontSize="small"/>
      </Grid>
      <Grid item>
        <Typography variant={"caption"} color="textSecondary">
          by Deeksha and Harit on
        </Typography>
      </Grid>
      <Grid item>
        <PublicIcon fontSize={"small"} className={classes.earthImage}/>
      </Grid>
    </Grid>
  )
}
