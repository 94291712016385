import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import LinkIcon from "@material-ui/icons/Link"
import { MyH1, MyH2, MyH4, MyH5, MyH6 } from "../../shared/MdxLayout"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  container: {
    "&:hover": {
      "& .heading-link": {
        visibility: "visible",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .heading-link": {
        visibility: "visible",
      },
    },
  },
  linkIcon: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    visibility: "hidden",
  },
}))

export default function AnchorLink({ heading, title }) {
  const anchor = title.toLowerCase().replace(/\s/g, "-")
  const classes = useStyles()
  const onAnchorClick = (linkHash) => navigate(`#${linkHash}`)

  return <Grid id={anchor} container justify="flex-start" alignItems="center" className={classes.container}>
    {heading === "h6" && <MyH6>{title}</MyH6>}
    {heading === "h5" && <MyH5>{title}</MyH5>}
    {heading === "h4" && <MyH4>{title}</MyH4>}
    {heading === "h2" && <MyH2>{title}</MyH2>}
    {heading === "h1" && <MyH1>{title}</MyH1>}
    <LinkIcon onClick={() => onAnchorClick(anchor)} className={`${classes.linkIcon} ${"heading-link"}`}/>
  </Grid>
}