import React from "react"
import { Grid, makeStyles } from "@material-ui/core"

export default function SectionP({ children, moreClasses }) {
  const classes = useStyles();

  return (
    <section>
      <Grid container classes={{
        root: classes.root,
        container: moreClasses
      }}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </section>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  }
}))
