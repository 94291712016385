/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { MuiThemeProvider } from "@material-ui/core"
import { theme } from "../theme"
import AppContainerStyled from "../containers/AppShellContainer"
import * as React from "react"

interface LayoutProps {
  selectedTabValue: number | false;
  children: JSX.Element;
  hideNews?: boolean
}
const Layout = ({ selectedTabValue, hideNews, children }: LayoutProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <AppContainerStyled
        hideNews={hideNews}
        selectedTabValue={selectedTabValue}
      >
        <main role="main">{children}</main>
      </AppContainerStyled>
    </MuiThemeProvider>
  )
}

export default Layout
