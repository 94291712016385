import { Button } from "@material-ui/core"
import AppBar from "@material-ui/core/AppBar"
import { withStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import PropTypes from "prop-types"
import React from "react"
import { Logo } from "./Logo"
import AppMenu from "../components/AppMenu"
import { MobileMenu } from "./MobileMenu"
import News from "./Common/News"

const styles = theme => ({
  root: {
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    border: "0px solid red",
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      border: "0px solid green",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
})

const AppHeader = ({ classes, hideNews, menus, selectedTabValue, onChange }) => {
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default">
        <Toolbar>
          <Button
            className={classes.menuButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={e => onChange(e, false)}
          >
            <Logo/>
          </Button>
          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <AppMenu items={menus} value={selectedTabValue} onChange={onChange}/>
          </div>
          <div className={classes.sectionMobile}>
            <MobileMenu items={menus} onChange={onChange}/>
          </div>
        </Toolbar>
        {!hideNews && <News/>}
      </AppBar>
    </div>
  )
}

AppHeader.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppHeader)
