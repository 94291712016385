import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import { NotificationsActiveRounded } from "@material-ui/icons"
import { NewsData } from "../../shared/constants"
import { navigate } from "gatsby"


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#be79df",
    cursor: "pointer",
  },
}))

const HIDE_ALL_NEWS = true // to hide/show all news
export default function News() {
  const classes = useStyles()
  const { message, destinationUrl } = NewsData

  if (HIDE_ALL_NEWS) return <></>

  return <Grid container alignItems={"center"}
               justify={"center"}
               spacing={2}
               onClick={() => navigate(destinationUrl)} className={classes.root}>
    <Grid item><NotificationsActiveRounded color="secondary"/></Grid>
    <Grid item>
      <Typography align={"center"} variant={"body2"} color="secondary">{message}</Typography>
    </Grid>
  </Grid>
}