import React from "react"
import { makeStyles, Fab, Grid } from "@material-ui/core"
import { ShareOutlined } from "@material-ui/icons"
import { TwitterShareButton } from "react-share"

const useStyles = makeStyles(theme => ({
  container: {
    position: "relative",
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  shareIcon: {
    marginRight: theme.spacing(1),
  },
  twitterShareButton: {
    display: 'flex',
    alignItems: 'center',
  }
}))

export default function Share({ title, slug, hashtags }) {
  const classes = useStyles()
  return (
    <Grid container className={classes.container}>
      <Fab
        size={"large"}
        variant="extended"
        aria-label={"Share"}
        className={classes.fab}
        color={"primary"}
      >
        <TwitterShareButton url={`https://bonsaiilabs.com${slug}`} title={title} via={'bonsaiilabs'} hashtags={hashtags} className={classes.twitterShareButton}>
          <ShareOutlined className={classes.shareIcon} /> Share
        </TwitterShareButton>
      </Fab>
    </Grid>
  )
}
