export const EventLoopJavaScript = {
  name: "Asynchronous JavaScript and Event Loop",
  level: "Beginner",
  duration: "",
  buyLabel: "Buy Now",
  buyUrl: "https://bonsaiilabs.thinkific.com/enroll/620256?price_id=656415",
  watchLabel: "Watch Trailer",
  watchUrl: "https://www.youtube.com/embed/d7BT3-IpMr8",
  reviews: [
    {
      name: "Kushagra",
      review:
        "Until this course I knew JavaScript promise work somehow but did not know how. This course covered the asynchronous paradigm in depth with examples that were easy to understand. What I loved the most was getting to learn the concepts followed by respective hands on code snippets for easy validation.",
    },
  ],
  details: {
    price: "$30.00",
    discountedPrice: "$20.00",
    smallDesc:
      "Master how browser runs JavaScript under the hood using asynchronous execution and event loop",
    about: {
      descriptions: [
        "JavaScript is #1 programming language in Software Development Ecosystem, according to GitHub. It is no wonder given that JavaScript exists everywhere - Web, Mobile, Desktop and even embedded devices. ",
        "Despite its popularity and ubiquitous presence, JavaScript developers often struggle with the core underpinnings of the language such as asynchronous execution in JavaScript, threading, processes, and event loop. ",
        "It not just limit the ability to solve complex problems, but also hinders career growth. As a developer, you may have stumbled upon questions such as",
        '- "What does synchronous vs asynchronous means?"',
        '- "What the heck is main thread?"',
        '- "Is JavaScript asynchronous?"',
        '- "Are javascript events asynchronous?"',
        '- "How to make asynchronous functions in javascript?"',
        '- "What is blocking vs non-blocking?"',
        '- "Is javascript single threaded?"',
        '- "How is javascript single threaded and asynchronous?"',
        '- "Is javascript in a browser single threaded?"',
        '- "What is event loop in JavaScript?"',
        '- "How is V8 related to JavaScript execution?"',
        "This course is designed to help you understand and answer these (and related) questions.",
        "We start by showing how JavaScript is executed in run time, followed by introducing the ExecutionContext, Event Loop Processing Model, Browser Threading, and Processes. The course also covers the topics of blocking (synchronous) vs non-blocking (asynchronous) computations with hands-on code examples. We will demonstrate the limitations introduced by blocking computations and how to mitigate those challenges. You will learn how JavaScript code is handled by the event loop and how browsers such as Chrome schedule and prioritize code execution on the main thread. ",
        "Throughout the course, we will refer to the Chromium open-source codebase and official specification of HTML APIs and ECMAScript to assert the knowledge and understanding in this area. We will work on multiple programming problems and quizzes to deepen your understanding and retention of knowledge.",
        "Understanding these topics separates a great developer from an average one! Just imagine, how far ahead you could be in your career once you can reason how asynchrony works in JavaScript.",
      ],
      learn: [
        "Understand how JavaScript ExecutionContext works.",
        "Testing knowledge by working on code examples and quizzes.",
        "Understand JavaScript Event loop Processing Model.",
        "Hands on Programming to demonstrate and distinguish between synchronous and asynchronous JavaScript code.",
        "Understand JavaScript threading and concurrency model.",
        "Break down how Browser (Google Chrome) works behind the scenes to render the web page.",
        "Understand how task execution is handled by Task and Microtask Queues based on the scheduling and priority.",
      ],
      outcome: [
        "You will have the ability to discern between topics such as synchronous, asynchronous, blocking, non-blocking, single-threaded, multi-threaded, ExecutionContext in JavaScript Ecosystem.",
        "You will have a thorough understanding of Event Loop in JavaScript.",
        "You will know how JavaScript code is scheduled and prioritized for execution on the main thread.",
        "You will grasp the inner workings of browser (Google Chrome) processes, tabs, threads, scheduler and the steps it takes to render the web content.",
        "You will know how V8, rendering engine (Blink), ECMAScript, Web APIs (e.g DOM, Fetch), and browser (Chrome) come together to display the web pages.",
        "You will stand out in your job or at interviews as you have mastered the core concept which is often misunderstood in JavaScript.",
      ],
      preReq: [
        "- You are passionate about JavaScript.",
        "- You can read and write loops, variables, and functions.",
      ],
      sideBar: {
        includes: [
          "Course support via private Slack Channel",
          "Lifetime Access",
          "Hands-On Code Examples",
          "Source Code",
          "Free Future Course Updates",
        ],
      },
    },
    instructors: ["Harit Himanshu", "Deeksha Sharma"],
    syllabus: [
      {
        moduleName: "All Lessons",
        moduleDuration: "1h 10m",
        numVideos: 19,
        clips: [
          {
            id: 0,
            duration: "02:32",
            name: "Learn about this course",
            videoId: "391115017",
          },
          {
            id: 1,
            duration: "02:10",
            name: "01. What are JavaScript Engines?",
          },
          {
            id: 2,
            duration: "03:07",
            name: "02. Relation between ECMAScript and JavaScript Engines",
          },
          {
            id: 3,
            duration: "04:48",
            name: "03. What is a Browser Rendering Engine?",
          },
          {
            id: 4,
            duration: "04:47",
            name: "04. Understanding Processes and Threads",
          },
          {
            id: 5,
            duration: "04:43",
            name: "05. Get to know Chrome Multi-Process Architecture",
          },
          { id: 6, duration: "01:38", name: "05.1. Demo of Browser Processes" },
          { id: 7, duration: "00:54", name: "05.2. Demo of Renderer Process" },
          {
            id: 8,
            duration: "01:10",
            name: "06. Blink in a nutshell",
            videoId: "391119207",
          },
          {
            id: 9,
            duration: "01:26",
            name: "07. What is the job of V8 in Chrome?",
          },
          {
            id: 10,
            duration: "02:59",
            name: "08. Code Walkthrough of Chromium",
          },
          {
            id: 11,
            duration: "04:07",
            name: "09. Synchronous JavaScript Execution",
          },
          {
            id: 12,
            duration: "05:15",
            name: "10. Blocking Execution on Main Thread",
          },
          {
            id: 13,
            duration: "09:31",
            name: "11. Event Loop and Asynchronous Execution",
          },
          {
            id: 14,
            duration: "08:23",
            name: "12. Blocking vs Non-Blocking Execution on UI",
          },
          {
            id: 15,
            duration: "02:41",
            name: "13. Introduction to JavaScript Promise",
            videoId: "391624979",
          },
          {
            id: 16,
            duration: "07:22",
            name: "14. Task Queue and MicroTask Queue of Event Loop",
          },
          {
            id: 17,
            duration: "03:05",
            name: "15. Scheduling and Prioritization of Tasks",
          },
          { id: 18, duration: "", name: "16. Q&A and Reading Materials" },
          {
            id: 19,
            duration: "",
            name: "17. Quizzes and Programming Problems",
          },
        ],
      },
    ],
    faqs: [
      {
        question: "What browser are we using in this course?",
        answer: "This course uses Google Chrome as the choice of browser.",
      },
      {
        question: "When will I have access to course videos?",
        answer:
          "Once you purchase the course, you will get the lifetime access to the course videos.",
      },
    ],
  },
}
