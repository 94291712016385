import React from "react"
import AppHeader from "../components/AppHeader"
import { AppMenus, Routes } from "../shared/constants"
import { withStyles } from "@material-ui/core"
import AppFooter from "../components/AppFooter/index"
import { navigate } from "gatsby"

class AppShellContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabValue: props.selectedTabValue
    };
  }

  handleChange = (event, value) => {
    // false is to un-highlight any selected  app menu and go to '/'
    let url = value === false ? Routes.appRoot : AppMenus[value].route;
    navigate(url);
  };

  render() {
    return (
      <>
        <AppHeader
          hideNews={this.props.hideNews}
          menus={AppMenus}
          selectedTabValue={this.state.selectedTabValue}
          onChange={this.handleChange}
        />
        <div>{this.props.children}</div>
        <AppFooter />
      </>
    )
  }
}
const styles = theme => ({
  content: {
  }
});
const AppContainerStyled = withStyles(styles, { withTheme: true })(AppShellContainer);
export default AppContainerStyled;
