export const MaterialUiBasic = {
  id: '17f25caf',
  name: 'Material-UI with React',
  level: 'Intermediate',
  duration: '2h 10m',
  buyLabel: 'Buy Now',
  buyUrl: 'https://bonsaiilabs.thinkific.com/enroll/483471?price_id=508080',
  watchLabel: 'Watch Trailer',
  watchUrl: 'https://www.youtube.com/embed/hhZ6yFvCWho',
  reviews:[
//     {
//       name: 'Vikneshwaran JK',
//       review: 'A combination of React with Material UI with a good project is rare. and I found this course very useful to learn Material UI and also how to think and apply the logic while building a product. Overall a great course, Explanation was very clear and concise.'
//     },
    {
      name: 'Pi Emmelot',
      review: 'Great way to learn about material-ui and react.'
    },
    {
      name: 'Alexei Mirkin',
      review: 'Excellent material'
    },
    {
      name: 'Janna Gilleland',
      review: 'Thank you so much. Your course is great!'
    },
  ],
  details: {
    price: '$40.00',
    discountedPrice: '$25.00',
    smallDesc: 'Learn to apply Google Material Design with React using Material-UI library',
    about: {
      descriptions: [
        'React is the most popular JavaScript framework for developing user interfaces with over 21 million downloads per month from npm. With companies like Uber, Facebook, Airbnb and many others using React, it is a solid choice to invest in learning',
        'But, how do you make beautiful user interfaces with React? How do you make websites that follow consistent theme, buttons, typography, inputs among other important elements? This is where React leaves a lot of room for other frameworks to fill.',
        'Material-UI is an excellent framework to develop beautiful React components faster! You can start with Google Material Design out of the box or create your own design system. This course teaches the fundamentals of Google Material Design and how to develop an end-to-end flight search and booking application using Material-UI and React.',
        'You will be able to showcase this project in your portfolio and to your prospective employers'
      ],
      learn: [
        'Creating a custom material theme from scratch',
        'Working knowledge of theme, typography, and Grid System',
        'Working knowledge of Material-UI Components from defaults to complete customisation',
        'Developing a project from scratch to a complete product ready to be deployed',
        'Hands-On programming of Stateless and Stateful React Components',
        'Fundamentals of Google Material Design',
        'Overview of ES6 features',
      ],
      preReq: ['- You must know to read and write React JS'],
      sideBar: {
        includes: [
          'Course support via private Slack Channel',
          'Lifetime Access',
          'Hands-On Project',
          'Source Code',
          'Free Future Course Updates'
        ]
      }
    },
    instructors: ['Deeksha Sharma'],
    syllabus: [
      {
        moduleName: 'Module 01: Introduction',
        moduleDuration: '14m',
        numVideos: 9,
        clips: [
          { id: 1, name: 'Learn about this course', clipNumber: '1', videoId: '389815399', duration: '01:39' },
          { id: 2, name: '01. Agenda', duration:'00:31' },
          { id: 3, name: '02. What are the Pre-requisites for this course?', duration:'00:22'},
          { id: 4, name: '03. What is covered in course?', duration:'01:00'},
          { id: 5, name: '04. What is not covered in this course?', duration:'00:24'},
          { id: 6, name: '05. About Author', duration:'00:36'},
          { id: 7, name: '06. Why Material-UI?', duration:'00:47', videoId: '391628236'},
          { id: 8, name: '07. Why Material-UI continued? (04:29)', duration:'04:29', videoId: '391628261'},
          { id: 9, name: '08. Project Setup', duration:'04:29' },
          { id: 10, name: '09. Summary', duration:'00:25' }
        ]
      },
      {
        moduleName: 'Module 02: Concepts And Syntax',
        moduleDuration: '15m',
        numVideos: 5,
        clips: [
          { id: 11, name: '01. Agenda', duration:'00:25' },
          { id: 12, name: '02. Quick Overview of ES6 Syntax', duration:'06:28' },
          { id: 13, name: '03. What is CSS-In-JS?', duration:'03:12', videoId: '391627172' },
          { id: 14, name: '04. What is Material-UI Grid?', duration:'04:27', videoId: '391627022' },
          { id: 15, name: '05. Summary', duration:'00:23' },
        ]
      },
      {
        moduleName: 'Module 03: Get Started With Stratafly',
        moduleDuration: '34m',
        numVideos: 8,
        clips: [
          { id: 16, name: '01. Agenda', duration:'00:20' },
          { id: 17, name: '02. Build Stratafly Header', duration:'07:37' },
          { id: 18, name: '03. Set Up Material Theme', duration:'06:29' },
          { id: 19, name: '04. Develop TripType And Traveller Components Part I', duration:'06:00' },
          { id: 20, name: '05. Develop TripType And Traveller Components Part II', duration:'04:31' },
          { id: 21, name: '06. Develop Location And Date Components', duration:'06:45' },
          { id: 22, name: '07. Develop Empty Results Component', duration:'01:29' },
          { id: 23, name: '08. Summary', duration:'00:23' },
        ]
      },
      {
        moduleName: 'Module 04: Search Results And Travellers Count',
        moduleDuration: '23m',
        numVideos: 5,
        clips: [
          { id: 24, name: '01. Agenda', duration:'00:24' },
          { id: 25, name: '02. Develop SearchResults Component', duration:'10:34' },
          { id: 26, name: '03. Develop TravellerDialog Component', duration:'09:06' },
          { id: 27, name: '04. Introduction To Flights Data Set', duration:'01:46' },
          { id: 28, name: '05. Summary', duration:'00:28' },
        ]
      },
      {
        moduleName: 'Module 05: Add State And Search Capabilities',
        moduleDuration: '16m',
        numVideos: 5,
        clips: [
          { id: 29, name: '01. Agenda',duration:'00:16' },
          { id: 30, name: '02. Implement Flight Search', duration:'03:40' },
          { id: 31, name: '03. Add State To SearchForm Component', duration:'04:43' },
          { id: 32, name: '04. Add Actions To TravellerDialog Component', duration:'06:15' },
          { id: 33, name: '05. Summary', duration:'00:28' },
        ]
      },
      {
        moduleName: 'Module 06: Select And Review Flights!',
        moduleDuration: '17m',
        numVideos: 5,
        clips: [
          { id: 34, name: '01. Agenda', duration:'00:26' },
          { id: 35, name: '02. Develop ReviewSelection Component', duration:'06:46' },
          { id: 36, name: '03. Develop ReviewSelection Component PartII', duration:'06:13' },
          { id: 37, name: '04. Develop ReturnFlights Component', duration:'03:36' },
          { id: 38, name: '05. Summary', duration:'00:23'}
        ]
      },
      {
        moduleName: 'Module 07: Flight Payment And Confirmation',
        moduleDuration: '12m',
        numVideos: 4,
        clips: [
          { id: 39, name: '01. Agenda', duration:'00:20' },
          { id: 40, name: '02. Develop And Integrate Payment Component',duration:'06:25' },
          { id: 41, name: '03. Develop And Integrate Confirmation Component', duration:'03:26' },
          { id: 42, name: '04. Summary', duration:'00:53' }
        ]
      }
    ],
    faqs: [
      {
        question: "When will I have access to course videos?",
        answer: "Once you purchase the course, you will get the lifetime access to the course videos.",
      },
    ],
  }
};
