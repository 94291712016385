import React from "react";
import { Grid } from "@material-ui/core";
import bonsaiilabs_logo from "../imgs/bonsaiilabs_logo.svg";

export const Logo = () => (
  <Grid container>
    <Grid item style={{ height: "100%", width: "100%" }}>
      <img
        src={bonsaiilabs_logo}
        alt="bonsaiilabs"
        style={{ maxWidth: "100%", maxHeight: "100%" }}
        loading={"lazy"}
      />
    </Grid>
  </Grid>
);
